<template>
  <div class="signIn">
    <div style="display: flex; justify-content: space-around">
      <v-img contain src="../assets/purple-logo-300.jpeg" max-width="210" />
    </div>
    <amplify-authenticator username-alias="email">
      <amplify-sign-in
        slot="sign-in"
        :hide-sign-up="true"
        username-alias="email"
      >
      </amplify-sign-in>
    </amplify-authenticator>
  </div>
</template>

<script>
import { Hub } from "aws-amplify";
export default {
  data() {
    return {
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false,
        },
      },
      username: "",
      message: {
        showError: false,
        errorMessage: null,
        show: false,
        message: null,
      },
    };
  },
  async created() {
    Hub.listen("auth", async (state) => {
      if (state.payload.event === "signIn") {
        this.$router.push({ path: "/" }, () => {});
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
